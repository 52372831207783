import React, {useState, useEffect} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {StaticImage} from "gatsby-plugin-image";
import {graphql} from 'gatsby';
import rearLatch from "../../../../images/shared/rear-units/deadlatch.png";
import rearBolt from "../../../../images/shared/rear-units/deadbolt.png";
import swatchBrushed from "../../../../images/shared/circle-brushed-nickle.png";
import swatchBlack from "../../../../images/shared/circle-matte-black.png";
import BANManagement from "../../../../components/pages/BANManagement";
import FaqSection from "../../../../components/pages/FaqSection";

import iconKeypadStnd from "../../../../images/shared/lock-icons/curve/curve-keypad.svg";
import iconKeypadStndOn from "../../../../images/shared/lock-icons/curve/curve-keypad-on.svg";
import iconRfidStnd from "../../../../images/shared/lock-icons/curve/curve-rfid.svg";
import iconRfidStndOn from "../../../../images/shared/lock-icons/curve/curve-rfid-on.svg";

import iconKeypadStndNet from "../../../../images/shared/lock-icons/curve/curve-smart-keypad.svg";
import iconKeypadStndNetOn from "../../../../images/shared/lock-icons/curve/curve-smart-keypad-on.svg";
import iconRfidStndNet from "../../../../images/shared/lock-icons/curve/curve-smart-rfid.svg";
import iconRfidStndNetOn from "../../../../images/shared/lock-icons/curve/curve-smart-rfid-on.svg";
import BookConsultation from "../../../../components/pages/BookConsultation";
import ProductDocuments from "../../../../components/products/ProductDocuments";


const IndexPage = () => {
  const {t} = useTranslation();

  // Body Style
  const [bodyStyleLocksOne, setBodyStyleLocksOne] = useState(true);
  const [bodyStyleLocksTwo, setBodyStyleLocksTwo] = useState(false);
  const [bodyStyleLocksThree, setBodyStyleLocksThree] = useState(false);
  const [bodyStyleLocksFour, setBodyStyleLocksFour] = useState(false);

  // Labels
  let curveLabels = [
    t('curve_label_1'),
    t('curve_label_2'),
    t('curve_label_3'),
    t('curve_label_4')
  ];
  const [bodyStyleLabel, setBodyStyleLabel] = useState(curveLabels[0]);

  //Brochures
  let curveBrochures = [
      'https://cdn.mediavalet.com/usva/digilock/oqsXGBEX20OXnz4NjolQ7w/s40ttXEb8EijDZLVHJMHAA/Original/DIGILOCK-CURVE-KEYPAD-PRODUCT-BROCHURE.pdf',
      'https://cdn.mediavalet.com/usva/digilock/HqBl1cRdv0mbjhwcFYy_Vw/YA-3aKWoKkyNaH2bIPs4pw/Original/DIGILOCK-CURVE-RFID-PRODUCT-BROCHURE.pdf',
      'https://cdn.mediavalet.com/usva/digilock/32uTnqg4ckqz664VTGZl-A/ps8iPeFuykK3NwTTZAUyOA/Original/DIGILOCK-CURVE-KEYPAD-NETWORK-PRODUCT-BROCHURE.pdf',
      'https://cdn.mediavalet.com/usva/digilock/mNpM1ffn2kykQFmTnKzLww/IXHWikMkqEyf7pc91tRQQQ/Original/DIGILOCK-CURVE-RFID-NETWORK-PRODUCT-BROCHURE.pdf'
  ];

  let dsCurveSmartKeypadStandard = 'https://cdn.mediavalet.com/usva/digilock/DOt_ph77fUmpZLPStL9A_A/v0SorKleQ0mjb3h8_9xj-w/Original/DS-D6CKN-D-DEN.pdf';
  let dsCurveSmartRFIDStandard = 'https://cdn.mediavalet.com/usva/digilock/BCfbkEOY5EST7c28zE-IVQ/l4mbfln3dUeHKy3XBnZq3g/Original/DS-D6CRN-D-DEN.pdf';

  let dsCurveKeypadStandard = 'https://cdn.mediavalet.com/usva/digilock/Rh3EqkradkiNPI4zWnvaSw/l4bVtaYDpUq6Hz9FYeM8ZQ/Original/DS-D6CKX-D-DEN.pdf';
  let dsCurveRFIDStandard = 'https://cdn.mediavalet.com/usva/digilock/FD-oI8bVWkuRDRfaB3l9jQ/0mIH5c-xeE-dWd_un5d62A/Original/DS-D6CRX-D-DEN.pdf';

  const [brochure, setBrochure] = useState(curveBrochures[0]);
  const [dataSheet, setDatasheet] = useState(dsCurveKeypadStandard);

  const resetLockImages = () => {
    setBodyStyleLocksOne(false);
    setBodyStyleLocksTwo(false);
    setBodyStyleLocksThree(false);
    setBodyStyleLocksFour(false);
  }

  const handleBodyStyleClick = (e) => {
    let data = e.target.getAttribute('data-value');
    resetLockImages();
    switch (data) {
      case 'one':
        setBodyStyleLocksOne(true);
        setBodyStyleLabel(curveLabels[0]);
        setBrochure(curveBrochures[0]);
        setDatasheet(dsCurveKeypadStandard);
        break;
      case 'two':
        setBodyStyleLocksTwo(true);
        setBodyStyleLabel(curveLabels[1]);
        setBrochure(curveBrochures[1]);
        setDatasheet(dsCurveRFIDStandard);
        break;
      case 'three':
        setBodyStyleLocksThree(true);
        setBodyStyleLabel(curveLabels[2]);
        setBrochure(curveBrochures[2]);
        setDatasheet(dsCurveSmartKeypadStandard);
        break;
      case 'four':
        setBodyStyleLocksFour(true);
        setBodyStyleLabel(curveLabels[3]);
        setBrochure(curveBrochures[3]);
        setDatasheet(dsCurveSmartRFIDStandard);
    }

  }

  useEffect(() => {
    // code
  }, [])


  return (
      <Layout>
        <Seo
            title={t('curve_title_tag')}
            description={t('curve_desc')}
        />
        <div className="products curve-page">

          <section className={'hero-pages'}>
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/curve/logo-gray-curve.png"
                        loading={'lazy'}
                        width={183}
                        height={52}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Aspire logo"
                    />
                    <h1>
                      <Trans>curve_headline</Trans>
                    </h1>
                    <p>
                      <Trans>
                        curve_headline_copy
                      </Trans>
                    </p>
                    <BookConsultation/>
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/curve/curve-family.png"
                        loading={'lazy'}
                        width={589}
                        height={381}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Curve famiily"
                        className={'curve-family'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="pivot" class="section-delimeter container"/>
          <section className={'appearance-functionality'}>
            <div className="container">
              <h1 className={'headline'} dangerouslySetInnerHTML={{__html: t('curve_access_lock')}}/>
              <div className="grid-two-col curve">
                <div className="grid-two-col-item-single center locks-container">
                  <div class="locks revealer">
                      <div className={'icons show'}>
                        <div>
                          <img src={bodyStyleLocksOne ? iconKeypadStndOn : iconKeypadStnd}
                               className={'lock-icon'}
                               onClick={handleBodyStyleClick}
                               data-roll={'key-standard'}
                               data-value={'one'}
                               width={'26'}
                               height={'49'}
                               alt="keypad standard"/>
                        </div>
                        <div>
                          <img src={bodyStyleLocksTwo ? iconRfidStndOn : iconRfidStnd}
                               className={'lock-icon'}
                               onClick={handleBodyStyleClick}
                               data-roll={'rfid-standard'}
                               data-value={'two'}
                               width={'26'}
                               height={'49'}
                               alt="rfid standard"/>
                        </div>
                        <div>
                          <img src={bodyStyleLocksThree ? iconKeypadStndNetOn : iconKeypadStndNet}
                               className={'lock-icon'}
                               onClick={handleBodyStyleClick}
                               data-roll={'rfid-standard-net'}
                               data-value={'three'}
                               width={'27'}
                               height={'54'}
                               alt="rfid standard network"/>
                        </div>
                        <div>
                          <img src={bodyStyleLocksFour ? iconRfidStndNetOn : iconRfidStndNet}
                               className={'lock-icon'}
                               onClick={handleBodyStyleClick}
                               data-roll={'key-standard-net'}
                               data-value={'four'}
                               width={'27'}
                               height={'54'}
                               alt="rfid standard"/>
                        </div>
                      </div>

                  </div>
                  <div className="locks">
                    <div className={'body-locks-container'}>
                      <StaticImage
                          className={bodyStyleLocksOne ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/curve/curve-keypad.png"
                          loading={'lazy'}
                          width={106}
                          height={210}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Curve Keypad"
                      />
                      <StaticImage
                          className={bodyStyleLocksTwo ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/curve/curve-rfid.png"
                          loading={'lazy'}
                          width={106}
                          height={210}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Curve RFID"
                      />
                      <StaticImage
                          className={bodyStyleLocksThree ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/curve/curve-keypad-net.png"
                          loading={'lazy'}
                          width={106}
                          height={210}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad Networked"
                      />
                      <StaticImage
                          className={bodyStyleLocksFour ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/curve/curve-rfid-net.png"
                          loading={'lazy'}
                          width={106}
                          height={210}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Curve RFID Networked"
                      />
                    </div>
                    <ProductDocuments productName={'curve'}
                        dataSheet={dataSheet}
                        dataSheetLabel={t('download_datasheet')}
                        lockLabel={bodyStyleLabel}
                        brochureLabel={t('download_brochure')}
                        brochure={brochure} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="pivot" className="section-delimeter container"/>

          <section className="page-benefits">
            <div className="container">
              <h1>
                <Trans>key_feat_bens</Trans>
              </h1>
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div class="parent">
                    <div>
                      <h3 dangerouslySetInnerHTML={{__html: t('product_highlights')}}/>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('All_metal_housing')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Advanced_auto_unlock')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Slim_0.41_(10.35_mm)_profile')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Non_handed')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('mech_ada')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('IP55_rated,_FCC,_CE_and_IC_certified')}}/>
                      </ul>
                      <h3 dangerouslySetInnerHTML={{__html: t('Access_Options')}}/>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('keypad')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('rfid')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Mobile_Access')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('elec_key')}}/>
                      </ul>
                    </div>
                    <div>
                      <h3><Trans>cleo_functionality</Trans></h3>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('Shared_Use_Mode')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Assigned_Use_Mode')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Reservation_Mode') + '<br />(' + t('networked_locks_only') + ')'}}/>
                      </ul>
                      <h3><Trans>cleo_installation</Trans></h3>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_1')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_3')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Snap_lock_cable_connector_(patented)_or_pin_connector')}}/>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={'locking-finish-items'}>
                  <div>
                    <h3><Trans>locking_options</Trans></h3>
                    <div className="thumbs">
                      <div>
                        <img
                            src={rearBolt}
                            width={128}
                            height={107} alt="bolt rear unit"/>
                        <p className={'curve-deadlatch'}><Trans>dual_use_deadbolt</Trans></p>
                      </div>
                      <div>
                        <img
                            className={'curve-rearlatch'}
                            src={rearLatch}
                            width={128}
                            height={107}
                            alt="cam rear unit"/>
                        <p className={'curve-deadlatch'}>
                          <Trans>auto_relock_deadlatch</Trans><br/>(<Trans>assigned_use_only</Trans>)</p>
                      </div>
                    </div>
                  </div>
                  <br/><br/>
                  <div>
                    <h3><Trans>finish_options</Trans></h3>
                    <div className="thumbs">
                      <div>
                        <img
                            src={swatchBrushed}
                            width={84}
                            height={84}
                            alt="brushed nickel finish"/>
                        <p className={'curve-swatch'}><Trans>brushed_nickel</Trans></p>
                      </div>
                      <div>

                        <img
                            src={swatchBlack}
                            width={84}
                            height={84}
                            alt="matte black finish"/>
                        <p className={'curve-swatch'}><Trans>matte_black</Trans></p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/><br/>
            <BookConsultation />
          </section>
          <div class="section-delimeter container"/>
          <section className="page-management">
            <BANManagement product={'Curve'}/>
          </section>
          <div id="pivot" className="section-delimeter container"/>
          <section className="faq-section">
            <FaqSection/>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
